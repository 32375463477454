@import "./media-queries";
@import "./colors";
@import "./fonts";

/**text properties**/

.h2 {
    @include text($text-color: $primary, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: null, $text-shadow: null, $text-transform: null);
    @include font-face($font-family: $primaryFont, $font-weight: 300, $font-size: 20px);
    text-transform: uppercase;

    &.white {
        color: white;
    }
}

.text {
    @include text($text-color: $grey, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: null, $text-shadow: null, $text-transform: null);
    @include font-face($font-family: $primaryFont, $font-weight: 500, $font-size: 12px);
    text-transform: uppercase;

    &.activeDarker {
        @include text($text-color: $darkerGrey, $text-decoration: null);
    }

    &.activelighter {
        color: $lighterGrey;
        @include text($text-color: $lighterGrey, $text-decoration: underline);
    }
}

.comingSoon {
    @extend .text;
    @include font-face($font-family: $primaryFont, $font-weight: 500, $font-size: 25px)
}

p {
    @include text($text-color: $secondary, $letter-spacing: 1px, $line-height: null, $text-align: null, $text-decoration: none, $text-shadow: null, $text-transform: null);
    @include font-face($font-family: $primaryFont, $font-weight: 500, $font-size: 12px);
    // color: $primary;
}

.link-paragraph {
    @include text($text-color: $lighterGrey, $letter-spacing: 1px, $line-height: null, $text-align: null, $text-decoration: underline, $text-shadow: null, $text-transform: null);
    @include font-face($font-family: $secondaryFont, $font-weight: 400, $font-size: 15px);
    @include margin($top: 18px, $right: null, $bottom: null, $left: null);
text-shadow: -2px 0px 30px rgba(0, 0, 0, .6);

}
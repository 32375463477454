.servizi-wrapper {
    .flex--child.servizi {
        width: 90%;
        flex-direction: row;
        flex-wrap: wrap;
        .servizi-wrapper-half.bottom {
            height: calc(100% - 80px);
        }
        .servizi-wrapper-half.top {
            height: 80px;

            .thumb-horizontal {
                background-color: rgba(210, 210, 210, 0.5);
                cursor: pointer;
                border-radius: 5px;
            }

            .servizi-wrapper-top-container {
                display: inline-block;
                white-space: nowrap;
                width: 100%;
                text-align: center;

                div.servizi-button {
                    width: auto;
                    display: inline-block;
                    text-align: center;
                }
            }

        }
    }
}


.floorplanning-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
  padding-right: 63px;

  .button {
    background-color: initial;
    height: initial;
    width: initial;
  }

  .fslightbox-full-dimension {
    z-index: initial;
  }

  *,
  *:before,
  *:after {
    box-sizing: initial;
  }
}

body.i-am-the-client {
  .floorplanning-container {
    .quotation-sx {
      visibility: hidden;
    }
    // Estendere l'immagine del prentivo di floorplanning a tutta la parte sinistra
    // per coprire la .quotation-sx nascosta
    .quotation-image {
      left: 0 !important;
      width: 70% !important;
    }
    .offcanvas-cart.amenities {
      visibility: hidden;
    }
  }

  .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
    visibility: hidden;
  }
}

body.i-am-the-client,
body.i-am-the-vendor {
  .floorplanning-container {
    .link-personalizzazioni {
      filter: opacity(0.5);
      pointer-events: none;
      cursor: default;
    }
  }
}

@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";

.flex--book {
  @include divDimensions(100%, 100%, $min-height: 70vh);
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $justify-content: center
  );
  @include position($position: relative);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    //url("../../images/esterni/thumbnails/01_esterni_thumbnail.jpg");
    url("/images/placeholder/400x400.jpg");
}

/**structural scss**/

@import './scss/structure/layout';
@import './scss/structure/buttons';
@import './scss/structure/texts';
@import './scss/structure/logos';

/**Common components scss**/
@import './scss/common/navbar';
@import './scss/common/basicStructure';
@import './scss/common/lightbox';
@import './scss/common/SliderGallery';
@import './scss/common/loading';
@import './scss/common/FullscreenToggler';
@import './scss/common/StandaloneImage';
@import './scss/common/slickSlider';
@import './scss/common/ErrorPage';

/**sigle pages scss**/

// @import "./scss/pages/Book";
@import './scss/pages/Capitolato';
@import './scss/pages/HomePage';
// @import "./scss/pages/InteriorPack";
@import './scss/pages/Lifestyle';
@import './scss/pages/Video';
@import './scss/pages/VirtualModel';
@import './scss/pages/virtualPhoto';
@import './scss/pages/virtualTour';
@import './scss/pages/vr';
@import './scss/pages/planimetrie';
@import './scss/pages/Sevizi.scss';
@import './scss/pages/Floorplanning.scss';
@import './scss/pages/interiorExperience';
@import './scss/pages/servizi';
@import './scss/pages/withCmsBar';

.absolute-position-relative-content-loader {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
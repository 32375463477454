@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";
@import "../structure/fonts";


/**custom alternative**/

.slider,
.slider:before,
.slider:after {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 90px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-box-sizing: border-box;
    background-color: $fourth;

}

.slider-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
}

.slide {
    display: inline-block;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.slider {
    .SliderButton {
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include backgroundImage ($background-color: $secondary, $background-image-url: null, $background-position: 45%, $background-repeat: no-repeat, $background-size: 50%);
        border-radius: 0;
        cursor: pointer;
        transition: transform ease-in .1s;
        top: 50%;
        position: absolute;
        z-index: 10;
    }
}

.nextArrow {
    right: 20px;
    transform: rotate(180deg);
}

.prevArrow {
    left: 10px;
}


.Indice--button-toggle {
    @include divDimensions (150px, 50px, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto);
    @include backgroundColor($background-color:$primary, $opacity:null);
    @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    cursor: pointer;
    color: $white;
    @include font-face($font-family:$thirdFont, $font-weight: 900, $font-style: null, $font-size: 14px);
    border-radius: 0;
    text-transform: uppercase;

    &.top {
        @include position($position: absolute, $top: 20px, $right: 100px, $bottom: null, $left: null);
        z-index: 100;
    }

    &.left {
        @include position($position: absolute, $top: 20px, $right: null, $bottom: null, $left: 50px);
        z-index: 10;

        &.on {
            display: none;
        }
    }

    &.fullImage {
        @include position($position: absolute, $top: 20px, $right: 100px, $bottom: null, $left: null);
        z-index: 10;

        &.on {
            display: none;
        }
    }

}
.indice--button {
    @extend .Indice--button-toggle;
    @include divDimensions (auto, 50px , $max-height: auto, $min-width: 150px, $min-height: auto);
    @include position($position: relative, $top: 0px, $right: auto, $bottom: auto, $left: null);
    @include backgroundColor($background-color:$primary, $opacity:null);
    @include margin($top: 10px, $right: 10px, $bottom: 10px, $left: 10px);
    padding: 10px;
    text-transform: uppercase;
    border-radius: 0;

    &.close {
        @include divDimensions (auto, 50px, $max-width: auto, $max-height: auto, $min-width: 50px, $min-height: auto);
        @include backgroundImage ($background-color: transparent, $background-image-url: null, $background-position: 50%, $background-repeat: no-repeat, $background-size: 50%);
        @include margin($top: 10px, $right: 10px, $bottom: 40px, $left: 10px);
        border: 2px solid $primary;
    }
}

.indice--button{
    width: 50%,
}

.indice--image--wrapper {
    @include divDimensions (800px, auto, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto);
    @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: wrap);

    & > .indice--image {
        @include divDimensions (150px, 50px, $max-width: auto, $max-height: auto, $min-width: auto, $min-height: auto);
        @include position($position: relative, $top: 0px, $right: auto, $bottom: auto, $left: null);
        @include backgroundImage ($background-color: transparent, $background-image-url: (""), $background-position: 50%, $background-repeat: no-repeat, $background-size: contain);
        @include margin($top: 20px, $right: 20px, $bottom: 20px, $left: 20px);
    }

}

.Indice--menu {
    z-index: 11;
    @include backgroundColor($background-color:$third, $opacity: 0.9);
    transition: all .6s ease-out;

    &.top {
        @include divDimensions (100%, 200px, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
        @include position($position: absolute, $top: 0px, $right: null, $bottom: null, $left: 0px);
        @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);

        &.off {
            @include position($position: absolute, $top: -250px, $right: null, $bottom: null, $left: null);
        }
    }

    &.left {
        @include divDimensions (500px, 100%, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
        @include position($position: absolute, $top: 0px, $right: null, $bottom: 0px, $left: 0px);
        @include flexContainer($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);

        &.off {
            @include position($position: absolute, $top: null, $right: null, $bottom: null, $left: -500px);
        }
    }

    &.fullImage {
        @include divDimensions (100%, 100%, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
        @include position($position: absolute, $top: 0px, $right: 0px, $bottom: 0px, $left: 0px);
        @include flexContainer($direction: row, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
        @include backgroundColor($background-color:$third, $opacity: 1);

        &.off {
            @include position($position: absolute, $top: -100%, $right: null, $bottom: null, $left: null);
        }
    }


    
}

.indice--image {
    cursor: pointer;
}
 @font-face {
     font-family: 'Poppins';
     src: url('../../fonts/Poppins.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 }

 @font-face {
     font-family: 'Poppins-Regular';
     src: url('../../fonts/Poppins-Regular.woff') format('woff');
     font-weight: normal;
     font-style: normal;
 }

 @font-face {
     font-family: 'Foco-Regular';
     src: url('../../fonts/Foco-Regular.woff') format('woff'),
        url('../../fonts/Foco-Regular.woff2') format('woff2');
     font-weight: normal;
     font-style: normal;
 }

 @font-face {
    font-family: 'NHaasGroteskTXPro-55Rg';
    src: url('../../fonts/NHaasGroteskTXPro-55Rg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NHaasGroteskTXPro-75Bd';
    src: url('../../fonts/NHaasGroteskTXPro-75Bd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'p22undergroundcypro';
    src: url('../../fonts/p22undergroundcypro-demi-webfont.woff2') format('woff2'),
        url('../../fonts/p22undergroundcypro-demi-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: SegoeRegular;
    src: url('../../fonts/SegoeUI.ttf') format('truetype');
}

 $primaryFont: 'primary-font', 'Poppins-regular';
 $secondaryFont: 'secondary-font', 'Poppins';
 $thirdFont: 'poppins';


 @mixin font-face($font-family:null, $font-weight: null, $font-style: null, $font-size: null, $font-color:null) {
     font-family: $font-family;
     font-weight: $font-weight;
     font-style: $font-style;
     font-size: $font-size;
     color: $font-color;
 }

@import './media-queries';
@import './colors';
@import './layout';
@import './image-path';

.logo {
  //@include backgroundImage ($background-image-url: ($imagePath+"icons/logo.svg"), $background-position: center, $background-repeat: no-repeat, $background-size: contain);
  @include backgroundImage($background-position: center, $background-repeat: no-repeat, $background-size: contain);
  @include divDimensions(45px, 45px);
  // transform: rotate(90deg);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.logo-extended {
  //@include backgroundImage ($background-image-url: ($imagePath+"icons/logo_extended.svg"), $background-position: center, $background-repeat: no-repeat, $background-size: contain);
  @include backgroundImage($background-position: center, $background-repeat: no-repeat, $background-size: contain);
  @include divDimensions(300px, 300px);
  //max-width: 300px;
  //max-height: 300px;
  //transform: rotate(90deg);
}

.logo---virtual-photo {
  //@include backgroundImage ($background-image-url: ($imagePath+"icons/logo.svg"), $background-position: center, $background-repeat: no-repeat, $background-size: contain);
  @include backgroundImage(
    $background-image-url: (
      $imagePath + 'placeholder/48x48.svg',
    ),
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: contain
  );
  @include divDimensions(200px, 200px);
  opacity: 1;
  position: relative;
  margin-right: 50px;
  // margin-left: 50px;
  //transform: rotate(90deg);
}

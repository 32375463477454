@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";

.plani-wrapper {
  @include divDimensions(
    1200px,
    700px,
    $max-width: auto,
    $max-height: auto,
    $min-width: 1200px,
    $min-height: 700px
  );
  @include backgroundImage(
    $background-image-url: (
      "https://www.dimoremilanesi.it/neurosales/planimetrie/planimetria-generale.jpg",
    ),
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: contain
  );

  @include flexContainer($direction: row, $align-items: top);
  @include position($position: relative);
}

.btn-select-building {
  @include divDimensions(
    40px,
    40px,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  @include backgroundColor($background-color: $primary, $opacity: null);
  position: relative;
  @include font-face(
    $font-family: $primaryFont,
    $font-weight: 900,
    $font-style: null,
    $font-size: 15px,
    $font-color: $white
  );
  text-decoration: none;
  cursor: pointer;
}

.plani-container {
  @include divDimensions(
    100vw,
    100vh,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  margin-right: 80px;
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $align-content: flex-start,
    $justify-content: flex-start,
    $flex-wrap: null
  );
}
.plani-img-left {
  @include divDimensions(
    70vw,
    100vh,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include flexChildren(
    $flex-grow: null,
    $flex-shrink: null,
    $flex-basis: null,
    $align-self: flex-start
  );
  @include backgroundImage(
    $background-image-url: (
      //$imagePath + "/homePage/homePage_cover-image.jpg",
      $imagePath + "/placeholder/1920x1200.jpg",
    ),
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: cover
  );
}
.plani-left {
  @include divDimensions(
    30vw,
    100vh,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include flexChildren(
    $flex-grow: null,
    $flex-shrink: null,
    $flex-basis: null,
    $align-self: flex-start
  );
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
}

.btn-piani {
  @include divDimensions(
    150px,
    40px,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  margin: 10px;
  @include border(
    $border-radius: 1px,
    $border-style: solid,
    $border-width: 2px,
    $border-color: $primary,
    $border-left-width: null,
    $border-top-width: null,
    $border-right-width: null,
    $border-bottom-width: null
  );
  @include backgroundColor($background-color: $third, $opacity: null);
  @include font-face(
    $font-family: $primaryFont,
    $font-weight: null,
    $font-style: null,
    $font-size: 18px,
    $font-color: $primary
  );
  text-decoration: none;
  cursor: pointer;
}

.font-1 {
  @include font-face(
    $font-family: $primaryFont,
    $font-weight: null,
    $font-style: null,
    $font-size: 18px,
    $font-color: $primary
  );
}

.planimetria_appartamento {
  @include divDimensions(
    100%,
    100%,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include backgroundImage(
    $background-image-url: null,
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: contain
  );
  @include flexContainer($direction: row, $align-items: top);
  @include position($position: relative);
}
.planimetria_piano {
  @include divDimensions(
    1200px,
    700px,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include backgroundImage(
    $background-image-url: null,
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: contain
  );
  @include flexContainer($direction: row, $align-items: top);
  @include position($position: relative);
}

.btn-select-planimetria {
  @include divDimensions(
    60px,
    40px,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $align-content: center,
    $justify-content: center,
    $flex-wrap: null
  );
  @include backgroundColor($background-color: $primary, $opacity: null);
  position: relative;
  @include font-face(
    $font-family: $primaryFont,
    $font-weight: 900,
    $font-style: null,
    $font-size: 15px,
    $font-color: $white
  );
  text-decoration: none;
  cursor: pointer;
  &.planimetria--sold{
    pointer-events: none;
    background-color: #b2b2b2;
  }
}

.appartamento-font-2{
  position: fixed;
  top: 10%;
  left: 40%;

}


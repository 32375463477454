// custom pin con colori da settings
.VT-button.interior-experience-with-settings-colors {
    border-radius: 50%;

    &.cono-not-active {
        background-color: #D2D2D2;
    }

    > span {
        width: 100%;
        height: 100%;
        display: inline-block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 90%;
    }
}
@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/texts";
@import "../structure/buttons";
@import "../structure/image-path";

.servizi-wrapper {
  display: flex;
  @include divDimensions(100%, 100%);
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    //url("../../images/book/card_book-verde.jpg");
    url("/images/placeholder/1500x1500.jpg");
  @include padding($top: 50px, $right: null, $bottom: 20px, $left: null);
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $justify-content: center
  );

  .flex--child.right {
    flex-direction: column;
  }
}

.servizi-wrapper-half {
  overflow: hidden;
  @include divDimensions(100%, 100%, $max-width: 1300px);

  &.bottom {
    @include divDimensions(100%, 100%);
    @include padding($top: 3%, $right: null, $bottom: null, $left: null);
    position: relative;

    img {
      @include divDimensions(100%, 100%);
      padding-left: 10px;
      padding-right: 10px;
      background-color: black;
      @include position(
        $position: absolute,
        $top: 20px,
        $right: null,
        $bottom: 30px,
        $left: null
      );
      object-fit: cover;
      object-position: 50% 50%;
      box-shadow: 8px 8px 15px 5px rgba(0, 0, 0, 1);
      opacity: 0;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0.6);
    }
    .active {
      z-index: 2;
      opacity: 1;
    }
  }
  &.top {
    @include divDimensions(100%, 100px);
    @include flexContainer(
      $direction: row,
      $align-items: center,
      $justify-content: center
    );
  }
}

.servizi-button {
  @include divDimensions(100%, auto, $min-width: 130px);
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $justify-content: center
  );
  background-color: $third;
  margin: 10px;
  text-transform: uppercase;
border-radius: 30px;
  @include font-face(
    $font-family: $thirdFont,
    $font-weight: 900,
    $font-style: null,
    $font-size: 14px,
    $font-color: $primary
  );
text-decoration: none;
  @include padding($top: 15px, $right: null, $bottom: 15px, $left: null);
  cursor: pointer;

  &.sport {
    min-width: 200px;
  }
  &.active {
    background-color: $primary;
    color: $secondary;
  }
  &.planimetrie{
      @include divDimensions(200px, auto, $min-width: 130px);

  }
  
}

@import '../structure/media-queries';
@import '../structure/colors';
@import '../structure/layout';
@import '../structure/image-path';

.video-wrapper {
  @include divDimensions(100%, 100%, $min-height: 70vh);
  @include position($position: relative);
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include backgroundColor($background-color: black, $opacity: null);

  padding-right: 8px;
  &.onTop {
    z-index: 100000;
  }
}

.video-player {
  height: 100%;
  width: 100%;
}

.video-background {
  @include position($position: relative);
  @include divDimensions(100%, 100%, $min-height: 70vh);
  @include backgroundImage($background-color: null, $background-image-url: null, $height: 100%, $background-position: center, $background-repeat: no-repeat, $background-size: cover);
}

.video-player-controller {
  position: absolute;
  top: 2vh;
  left: 3vh;
}

.video-player-volume-slider {
  width: 10vw;
}

button.video-player-volume-button {
  color: #fff;
}

.video-react-player {
  position: absolute;
  top: 0;
  left: 0;
}
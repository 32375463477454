@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";

.flex--home {
    @include backgroundImage ($background-position: 60%, $background-repeat: no-repeat, $background-size: contain);
    @include divDimensions (100%, 100%, $min-height: 70vh);
    @include position($position: relative);
    @include flexContainer ($direction: row, $align-items: stretch, $justify-content: center);
    background-color: transparent;
    @include backgroundImage ($background-position: 30%, $background-repeat: no-repeat, $background-size: contain);

    @include excludeDesktop {}
}

.flex--child {
    @include position($position: relative);
    @include flexContainer ($direction: row, $align-items: center, $justify-content: center);
    flex-grow: 1;


    &.left {
        max-width: 700px;
        min-width: 450px;
        @include margin($top: 5%, $right: -5%, $bottom: 5%, $left: 60px);
        z-index: 4;
    }

    &.right {
        @include flexContainer ($direction: row, /*$align-items: flex-end,*/ $justify-content: center);
        //@include backgroundImage ($background-image-url: ($imagePath+"/homePage/homePage_cover-image.jpg"), $background-position: center, $background-repeat: no-repeat, $background-size: cover);
        @include backgroundImage ($background-position: center, $background-repeat: no-repeat, $background-size: cover);

        &.capitolato {
            @include flexContainer ($direction: row, $align-items: center, $justify-content: center); 
        }
    }
}

.grid--home {
    @include divDimensions (100%, 100%, $min-height: 500px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.right-child-wrapper {
    display: flex; 
    flex-direction: column;
}
  
.footer-notes-ns {
    margin-bottom: 400px;
    opacity: 0.5;
    background-color: #000000;
    width: 670px;
    padding-right: 10px;
    padding-left: 4px;
    .disclaimer.center-text{
        .parNote {
            color: white !important;
            font-size: 6px;
            opacity: 0.5;
        }
    }
}

.note-footer-container {
    z-index: 10;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    
    @keyframes shrink {
        0% { max-height: 50vh; }
        100% { max-height: 0; }
    }

    .note-info-container {
        transition: max-height 0.15s ease-out;
        max-height: 0;
    }

    &.open {

        .note-info-container {
            max-height: 500px;
            transition: max-height 0.25s ease-in;
        }
    }

    &.open {
        .arrow-down {
            transform: rotate(180deg);
            top: -2px!important;
        }
    }

    .note-title-container {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        color: #5C5C5C;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-right: 10px;
        padding-left: 10px;
        font-size: 12px;
        .arrow-down {
            display: inline-block;
            height: 10px;
            width: 10px;
            position: relative;
            left: 5px;
            top: 0px;
            filter: invert(1);

            &:before {
                content: '';
                position: absolute;
                height: 20px;
                width: 20px;
                left: 50%;
                top: 50%;
                filter: invert(1);
                transform: translate(-50%, -50%);
            }
        }
    }

    .note-info-container {
        .footer-notes-container {
            color: #5C5C5C;
            font-size: 12px;
            padding: 5px 10px;
        }
    }
}

// Modifiche dopo introduzione CMS per sistemare layout
html, body {
    height: 100%;
    width: 100%;
    position: unset;

    #root, .app-container, .app-hero-container {
        height: 100%;
        width: 100%;
    }

    .home-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        > .heroSection {
            position: relative;
            height: 100%;
            width: 100%;
            flex-direction: unset;

            .wrapper--100 {
                height: 100%;
                width: 100%;
            }

            .grid--home {
                a {
                    min-height: auto;
                }
            }
        }
    }
}
// CSS SPECIFIC FOR NS WITH CMS TOP BARS

.home-container.with-cms-bar {
  .heroSection {
    height: calc(100% - 72px);
  }

  #floorplanning-app {
    .sidebar {
      .sidebar_column {
        margin-top: 131px;
      }
    }
  }

  .interior-experience-container {
    .VT-wrapper-half.right-half {
      overflow-y: auto;
    }
  }

  .navbar {
    height: calc(100vh - 72px);
    .navIconWrapper {
      display: none;
    }
  }
}

/**global colors**/
$primary: #43262b;
$secondary: #b38e37;
$third: #b38e37;
$fourth: #E5E6E1;
$fifth: #f0f0f0;
$white: white;
$black: black;
$grey: #bababa;
$darkerGrey: #363636;
$lighterGrey: #e6e6e6;
$active: #7ab943;
$transparent: rgba(0, 0, 0, 0);
$gradient: radial-gradient(circle, rgba(84, 84, 84, 1) 0%, rgba(40, 40, 40, 1) 96%);
//.... ad as many color you need
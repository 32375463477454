@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/texts";
@import "../structure/buttons";
@import "../structure/image-path";


$zero: 0px;
$presetBorder: 1px;
$presetMargin: 10px;
$presetPadding: 10px;
$heroMargin: 30px;
$ShadowColor: rgba(00, 00, 00, 0.2);
$ShadowBlur: 10px;
$ShadowBlurLight: 2px;
$imagePath: "/images";





.heroSection {
    @include divDimensions(100%, 100vh);
    @include flexContainer($direction: column, $align-items: top);
    @include position($position: fixed);
    // z-index: 100;
    max-width: 100vw;

    background-color: red($color: #000000);
}

.heroWrapper {
    @include divDimensions(100%, 100%);
    @include flexContainer($direction: row, $align-items: top);
    @include position($position: fixed);
    // z-index: 100;
}



.videoContainer {
    @include divDimensions(100%, 100%);
    @include flexContainer($direction: row, $align-items: top);
    @include position($position: fixed);
    z-index: 10;
    //opacity: .5;
}

.Player360 {
    @extend .videoContainer;
    z-index: 8;
}

.react-player {
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    height: auto !important;
}

.HeroChild {
    @include divDimensions(100%, 100%);
    @include backgroundColor($background-color: black, $opacity: null);

}

.videoContainer video {
    object-fit: fill;
    position: absolute;
}

.imageDisplay {
    @include margin($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
    @include padding($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
    @include position($position: fixed);
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}


.knobWrapper {
    @include position($position: fixed, $top: null, $right: 100px, $bottom: 50px, $left: null);
    @include divDimensions(320px, 320px);
    // @include flexContainer($direction: row, $align-items: center, $justify-content: center);
    @include backgroundColor($background-color: rgba(255, 0, 0, 0), $opacity: null);

    z-index: 9;

    &.isSafari {
        @include divDimensions(240px, 240px);
        @include position($position: fixed, $top: null, $right: 140px, $bottom: 90px, $left: null);


    }
}

.btnVideo {
    position: relative;
    width: 20px;
    height: 20px;
    @include border($border-radius: 50%, $border-style: solid, $border-width: 1px, $border-color:rgb(255, 255, 255), $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
    background-size: cover;

    &.clickable {
        @include backgroundColor($background-color:rgb(56, 56, 56), $opacity: 1);

    }

    &.activated {
        @include backgroundColor($background-color:#b4aaa0, $opacity: 1);
    }

    &.deactivated {
        @include backgroundColor($background-color:null, $opacity: .3);
    }

}

.position_1 {
    @extend .btnVideo;
    left: 50%;
    transform: translate(-50%, 50%);
    //background: url("#{$imagePath}/icons/arrow.svg") no-repeat center;
    background: url("#{$imagePath}/placeholder/48x48.svg") no-repeat center;
}

.position_2 {
    @extend .btnVideo;
    top: 50%;
    left: 100%;
    transform: translate(-150%, -150%) rotate(90deg);
    //background: url("#{$imagePath}/icons/arrow.svg") no-repeat center;
    background: url("#{$imagePath}/placeholder/48x48.svg") no-repeat center;
}

.position_3 {
    @extend .btnVideo;
    top: 100%;
    left: 50%;
    transform: rotate(190deg);
    transform: translate(-50%, -350%) rotate(180deg);
    //background: url("#{$imagePath}/icons/arrow.svg") no-repeat center;
    background: url("#{$imagePath}/placeholder/48x48.svg") no-repeat center;
}

.position_4 {
    @extend .btnVideo;
    top: 50%;
    left: 0%;
    transform: rotate(270deg);
    transform: translate(50%, -350%) rotate(270deg);
    //background: url("#{$imagePath}/icons/arrow.svg") no-repeat center;
    background: url("#{$imagePath}/placeholder/48x48.svg") no-repeat center;
}

.sc-bdVaJa {
    @include position($position: fixed, $top: null, $right: -160px, $bottom: -75px, $left: null);
    transform: translate(-50%, -50%);
}

.internalKnob {
    @include position($position: fixed, $top: null, $right: 207px, $bottom: 165px, $left: null);
    @include divDimensions(105px, 105px);
    @include border($border-radius: 50%, $border-style: solid, $border-width: 5px, $border-color:rgb(56, 56, 56), $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
    z-index: 9;
    -moz-box-shadow: 5px 5px $ShadowBlur 5px $ShadowColor;
    -webkit-box-shadow: 5px 5px $ShadowBlur 5px $ShadowColor;
    box-shadow: 5px 5px $ShadowBlur 5px $ShadowColor;
    // background: url("#{$imagePath}/icons/garofalo_rotate-btn.svg") no-repeat center,
    // linear-gradient(45deg, #336565 0%, #336565 100%, );
    background-size: cover;

}

.sliderHandle:after {
    background: rgb(56, 56, 56) !important;
    box-shadow: 0 0 2px rgb(1, 30, 37) !important;
}

.sliderHandle:hover:after {
    box-shadow: 0 0 2px rgb(1, 30, 37);
}

.positionController {
    position: fixed;
    bottom: 0px;
    width: 240px !important;
    height: 240px;
    background-color: $active;
}
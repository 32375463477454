@import "../structure/colors";
.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    /*-webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;*/
    animation: load7 1.8s infinite ease-in-out;
}

.loader {
    color: $primary;
    font-size: 10px;
    margin: 80px auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

.sw-progress-container {
    display: flex;
    flex-direction: column;
    color: #ffffff;
    align-items: center;
    gap: 1.25rem;
    .sw-progress-logo {
        height: 50px;
        width: 50px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: contain; 
        filter: brightness(0) invert(1);  
    }
    .sw-progress-text {
        font-family: 'SegoeRegular', sans-serif;
        text-align: center;
        font-weight: 300;
        &.sw-progress-title {
            font-size: 42px;
        }
        &.sw-progress-info {
            font-size: 16px;
        }
        &.sw-progress-percentage {
            font-size: 32px;
        }
    }
    .progress-bar-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -1;
        .MuiLinearProgress-root {
            height: 12px;
            margin-top: 130px;
        }
    }
}

.custom-snackbar-container {
    position: absolute;
    left: 75%;
    transform: translate(-50%);
    top: auto;
    right: auto;
    bottom: 25px;
    display: flex;
    width: 40%;
    height: 70px;
    justify-content: flex-start;
    align-items: center;
    border-style: solid;
    border-width: 4px;
    border-color: #fff;
    border-radius: 150px;
    background-color: #333333;
    z-index: 3;
    opacity: .8;
    padding: 0px 10px;
    .custom-snackbar-container-text {
      margin-left: 10px;
      color: #fff;
      font-size: 12px;
      line-height: 13px;
      padding-right: 10px;
      flex-grow: 1;
      .progress-bar-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
      }
      .MuiLinearProgress-colorPrimary {
          background-color: transparent;
      }
      .MuiLinearProgress-barColorPrimary {
          background-color: #fff;
      }
      .MuiTypography-body2 {
          color: #ffffff;
      }
      .snackbar-subtitle {
          font-size: 9px;
      }
    }
}

@-webkit-keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
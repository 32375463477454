$COLOR_PRIMARY: #000000 !default;
$CARD_BACKGROUND: #ffffff !default;
$COLOR_SECONDARY: #999 !default;

.dot {
  height: 60px;
  width: 60px;
  background-color: #ffff00;
  border-radius: 100%;
  position: absolute;
  z-index: 30000;
  animation: anim-effect-boris 0.55s forwards;
}

.dot.dot-move {
  height: 15px;
  width: 15px;
}

@keyframes anim-effect-boris {
  0% {
    transform: scale3d(0.3, 0.3, 1);
  }
  25%,
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1);
  }
}

.overlay-protector {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background: none;
  width: 200%;
  height: 200%;
}

@-webkit-keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  50% {
    opacity: 1;
  }
}

.MuiDialog-root {
  z-index: 91000000 !important;
}

.video-container {
  z-index: 9100005;
  position: fixed;
  top: 20px;
  right: 90px;
  width: 135px;

  .backToNeuro {
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    text-align: center;
    padding: 7px;
    margin: 10px;
    cursor: pointer;
    font-size: 11px;
    color: #333;
    font-weight: bold;
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: none;
  }

  .remote-video {
    background: #000000;
    border-radius: 10px;
    z-index: 9998;
    max-width: 100%;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
  }

  .local-video {
    width: 1px;
    height: 1px;
    position: absolute;
  }

  .video-controller {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: #eee;
    border-radius: 10px;
    font-size: 0px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
    .video-control {
      padding: 10px;
      cursor: pointer;
    }
  }

  .video-controller-call {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 9px;
    width: 100%;
  }

  #makeCall {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #eee;
    cursor: pointer;
    div {
      margin: 5px;
    }
  }

  .waiting-user {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #eee;
    -webkit-animation: fadeinout 4s linear infinite;
    animation: fadeinout 4s linear infinite;
    opacity: 0;
    div {
      margin: 5px;
    }
  }

  .established-connection {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #eee;
    font-size: 20px;
    div {
      margin: 10px;
    }
  }
}

.i-am-the-vendor,
.i-am-the-client {
  .ext-link {
    pointer-events: none;
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  // Blocco la possibilità di fare zoom nelle virtualphoto
  .controller-button.zoom-in,
  .controller-button.zoom-out {
    pointer-events: none;
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  // Blocco la possibilità di fare zoom e scrollare cliccando o trascinando la foto in virtualphoto
  .slick-slider-images {
    pointer-events: none;
  }
}

#videochat-draggable-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1vw;
  pointer-events: none;
}

.drag-here {
  cursor: move;
}

// Ridimensionare la finestra della videocall
.video-container {
  transition: width 400ms ease-in-out;

  &.S {
    width: 130px;
    .videocall-message {
      font-size: 10px !important;
    }
    #makeCall {
      padding-top: 5px;
    }
    .videocall-control {
      button {
        padding: 5px;
      }
      svg {
        height: 18px;
      }
    }
  }
  &.M {
    width: 260px;
    .videocall-message {
      padding-top: 25px;
      font-size: 15px;
    }
  }
  &.L {
    width: 390px;
    .videocall-message {
      padding-top: 50px;
      font-size: 20px;
    }
  }
  &.fullscreen {
    width: 85% !important;
    height: 95%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    .drag-here {
      pointer-events: none;
    }
    .videocall-message {
      padding-top: 100px;
      font-size: 30px;
    }
  }

  .videocall-message {
    transition: all 400ms ease-in-out;
    font-weight: 100;
  }
  .videocall-control {
    button {
      transition: all 400ms ease-in-out;
    }
    svg {
      transition: all 400ms ease-in-out;
    }
  }

  .remote-video {
    width: 100%;
    height: 95%;
  }
}

.i-am-the-vendor .video-container .remote-video {
  height: 80%;
}

.videocall-control button {
  padding: 10px;
  width: 100%;
}

.i-am-the-client .videocall-client-disabled {
  cursor: not-allowed !important;
}

.i-am-the-client .videocall-client-hidden {
  display: none !important;
}

.i-am-the-vendor .videocall-vendor-hidden {
  display: none !important;
}

.videocall-reconnection-message {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4vh;
  color: white;
  user-select: none;
  cursor: wait;
  backdrop-filter: blur(3px);

  span.dot-animated {
    position: absolute;
    text-align: left;

    &:before {
      animation: dots 2s linear infinite;
      content: '';
    }
  }

  @keyframes dots {
    0%,
    20% {
      content: '.';
    }
    40% {
      content: '..';
    }
    60% {
      content: '...';
    }
    90%,
    100% {
      content: '';
    }
  }

  .videocall-reconnection-timeout {
    display: block;
    text-align: center;
    font-size: 2vh;
    font-weight: 100;
    padding-top: 1vh;
  }
}

.video-info-msg {
  margin-top: 3px;
  padding: 10px;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: #eee;
  border-radius: 10px;
  font-size: 11px;
  text-align: center;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.46);
  transition: opacity 500ms;
  &.hidden {
    opacity: 0;
  }
}

.videocall-virtual-tour {
  position: absolute;
  background-color: white;
  z-index: 2;
  top: 20px;
  left: 20px;
  padding: 0 20px;
  border-radius: 29px;
  transition: width 20ms;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  display: none;
  align-items: center;
  .vendor-switch span {
    padding-right: 10px;
  }
}

@media only screen and (min-device-width: 1500px) and (orientation: landscape) {
  .videocall-virtual-tour {
    top: initial;
    bottom: 20px;
  }
}

.i-am-the-client,
.i-am-the-vendor {
  .navIcons.no-tap,
  .navIcons.refresh {
    display: none;
  }

  .videocall-virtual-tour {
    display: flex !important;
  }

  .cms-top-bar {
    display: none;
  }
  .home-container.with-cms-bar .heroSection {
    height: 100%;
  }

  .home-container.with-cms-bar #floorplanning-app .sidebar .sidebar_column {
    margin-top: unset;
  }
}

// NEW GROUP VIDEOCALL
div.user-video {
  position: absolute;
  bottom: 70px;
  right: 10px;
  z-index: 10000000;
  width: 75px;

  button {
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.75);
    margin-bottom: 10px;
    &:hover {
      background-color: rgba(255, 255, 255, 1);
    }
    &.screen-sharing-active {
      background-color: rgba(118, 255, 3, 0.9);
    }
    &.screen-sharing-active:hover {
      background-color: rgba(255, 23, 68, 0.9);
    }
  }

  video {
    height: 75px;
    width: 75px;
    border-radius: 100%;
    box-shadow: 0px 0px 19px 1px rgba(0, 0, 0, 0.75);
    object-fit: cover;
  }
}

div.other-videos-container {
  position: absolute;
  z-index: 10000000;
  top: 10px;
  cursor: grab;
  right: 10px;
  width: 10%;
  transition: height 0.25s, width 0.25s;

  &.is-dragging {
    cursor: grabbing;
  }

  &.hide {
    display: none;
  }
  &.big-video {
    width: 25%;
  }

  & > div {
    min-width: 50%;
    video {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  &.fullscreen {
    width: 85%;
    height: 95%;
    transform: translate(-50%, -50%) !important;
    right: initial;
    top: 50%;
    left: 50%;
    cursor: initial;
    justify-content: center;
    & > div {
      max-height: 100%;
    }
    &.half-height > div {
      max-height: 50%;
    }
    & .videocall-resize-button {
      display: none;
    }
  }
  &.screen-sharing {
    & > div {
      display: none;
      &.screen-sharing-video {
        display: initial;
      }
    }
  }
}

button.videocall-resize-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.6);
  left: -35px;
  &:hover {
    background-color: white;
  }
}

.videocall-vendor-snackbar .MuiPaper-root {
  justify-content: center;
}

.show-other-controls {
  &.expanded {
    background-color: white;
  }
}

.videocall-dialog {
  .videocall-dialog-message {
    padding: 0 24px 8px;
    text-align: center;
  }
}

.i-am-the-client *:not(.videochat-client-mouse-enabled, .videochat-client-mouse-enabled *) {
  cursor: default;
}

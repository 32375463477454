.slick-slider-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: 1000000;
    background: linear-gradient(rgba(30,30,30,.9),#000 1810%);

    &.is-visible {
        visibility: visible;
    }

    &.no-zoom {
        .image-wrapper-zoom {
            img {
                cursor: grab;
            }
        }
    }

    .slick-slider, .slick-list, .slick-track {
        height: 100%;
    }

    .arrow-next-container {
        transform: translate(50%, -50%);
    }
    .arrow-prev-container {
        transform: translate(-50%, -50%);
    }

    .arrow-next-container, .arrow-prev-container {
        position: absolute;
        z-index: 10;

        .arrow-slide {
            height: 40px;
            width: 40px;
            background-color: #b38e37;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.nextArrow {
                transform: unset!important;
            }

            &::before {
                content: "";
                width: 20px;
                height: 20px;
                filter: invert(1);
            }
        }
    }

    .arrow-prev-container {
        top: 50%;
        left: 2%;
    }

    .arrow-next-container {
        top: 50%;
        right: 2%;
    }

    .slick-slider-controller {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        background-color: rgba(35,35,35,.65);

        .controller-button {
            cursor: pointer;
            height: 45px;
            width: 45px;
            display: inline-flex;

            &:before {
                content: '';
                width: 45px;
                height: 45px;
                background-size: 15px;
                background-position: center;
                background-repeat: no-repeat;
                filter: invert(1);
            }
        }
    }

    .slick-slider-images {
        position: absolute;
        width: 96%;
        height: 96%;
        left: 2%;
        top: 2%;

        &.rotated-thumbs {
            top: 2%;

            .slick-list {
                padding-top: 30px;
                padding-bottom: 0px;
            }

            ul.slick-dots.slick-thumb {
                top: 0px;
                height: 60px;

                // .thumb-container {
                //     img {
                //         transform: rotate(180deg);
                //     }
                // }
            }
        }

        
        .image-wrapper-zoom {
            position: relative;
            img {
                position: absolute;
                max-height: 90%;
                max-width: 90%;
                transition: left 0.25s linear, top 0.25s linear, max-width 0.25s linear, max-height 0.25s linear; 
            }
        }

        &.no-thumbs {
            .image-container {
                img {
                    max-height: 96%;
                    max-width: 96%;
                }
            }

            .slick-list {
                padding-bottom: 0px;
                padding-top: 0px;
            }
        }

        .slick-list {
            padding-bottom: 30px;
            -webkit-transition: padding-bottom 100ms linear, padding-top 100ms linear;
            //-webkit-transition: padding-top 100ms linear;
        }

        img {
            cursor: zoom-in;
        }

        .slick-slide {
            width: inherit;
            height: inherit;

            > div, .image-container, img, .image-wrapper-zoom {
                width: inherit;
                height: inherit;
            }
        }

        .image-wrapper-zoom {
            overflow: hidden;
            padding: 0px 5px;
            box-sizing: border-box;
        }

        button {
            width: 35px;
            height: 35px;
            background-color: #b38e37;
            border-radius: 35px;

            &:before {
                visibility: hidden;
            }

            &.slick-prev {
                left: -80px;
            }

            &.slick-next {
                right: -80px
            }
        }

        .slick-dots.slick-thumb li {
            width: auto;
            height: auto;
            .thumb-container img {
                height: 60px;
                width: auto;
                cursor: pointer;
            }

            &.slick-active {
                .thumb-container {
                    &:before {
                        content: "";
                        height: 5px;
                        width: 100%;
                        background-color: #b38e37;
                        bottom: 0;
                        left: 0;
                        position: absolute;
                    }
                }
            }
        }
    }

    .image-container, img {
        outline: none;
    }

    img {
        height: inherit;
        width: 100%;
        object-fit: contain!important;
        outline: none;
    }

    .arrow-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        &.container-left {
            left: 5%
        }

        &.container-rigth {
            right: 5%;
        }

        .arrow-image {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: #b38e37;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }


    // THUMBS
    .slick-slider-thumbs {
        bottom: 20px;
        position: absolute; 
        max-width: 90%;
        left: 50%;
        transform: translateX(-50%);

        &.rotated-thumbs {
            top: 20px;
            bottom: unset;
        }

        &.no-thumbs {
            visibility: hidden;
        }

        .hor-scroll-container-thumbs {
            display: flex;
            align-items: center;
            justify-content: center;

            .container-thumbs {
                height: 60px;
                display: inline-flex;
                align-items: center;
                width: 100%;
            }
    
            .thumb-container {
                cursor: pointer;
                margin-right: 10px;
                height: 60px;
                display: inline-flex!important;
                position: relative;

                img {
                    width: auto;
                }

                .row-selected {
                    height: 5px;
                    width: 100%;
                    background-color: #b38e37;
                    bottom: 0;
                    left: 0;
                    position: absolute;
                    display: none;
                    transform: none;
                }
            }
    
            .thumb-container.current {
                .row-selected {
                    display: block;
                }
            }
        }
    }
    
}
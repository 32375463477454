@import '../structure/media-queries';
@import '../structure/colors';
@import '../structure/layout';
@import '../structure/texts';
@import '../structure/buttons';
@import '../structure/image-path';

.VT-wrapper {
  display: flex;
  @include divDimensions(100%, 100%);
  background-color: $lighterGrey;
  @include padding($top: 20px, $right: 30px, $bottom: 20px, $left: 60px);
}

.VT-wrapper-half {
  overflow: hidden;
  @include divDimensions(100%, 100%);

  &.left-half {
    @include divDimensions(70%, 97%);
    @include padding($top: 3%, $right: null, $bottom: null, $left: null);
    position: relative;
    margin-right: -70px;

    img {
      @include divDimensions(100%, 100%);
      @include position($position: absolute, $top: 20px, $right: null, $bottom: 30px, $left: 0);
      object-fit: cover;
      object-position: 50% 50%;
      box-shadow: 8px 8px 15px 5px rgba(0, 0, 0, 0.2);
      opacity: 0;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      filter: alpha(opacity=0);
    }
    .active {
      z-index: 2;
      opacity: 1;
    }
  }
  &.right-half {
    flex: 0 0 35%;
    background: $lighterGrey;
    background: radial-gradient(circle farthest-corner at 50% 50%, $white, rgba(212, 194, 146, 0.5));
    .right-half__elements {
      padding: 3% 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      div {
        &.logo-extended {
          min-height: 60px;
          height: 80px;
          width: 100%;
          margin-bottom: 8px;
          @include border(
              $border-radius: null,
              $border-style: solid,
              $border-width: 0px,
              $border-color: $lighterGrey,
              $border-left-width: 0px,
              $border-top-width: 0px,
              $border-right-width: 0px,
              $border-bottom-width: 3px
            );
        }
      }
    }
  }
}

.VT-plan {
  flex: 1 1 auto;
  width: calc(100% - 90px);
  margin-right: 8px;
  align-self: flex-end;
  max-height: 600px;
  &.bilocale {
    @include backgroundImage(
      //$background-image-url: ($imagePath + "interior-tour/interior-tour_bilocale_planimetria.png"), $background-position: center, $background-repeat: no-repeat, $background-size: contain);
      $background-image-url: ($imagePath + 'placeholder/1400x1400.jpg'),
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: contain
    );
    position: relative;
  }
  &.quadrilocale {
    @include backgroundImage(
      //$background-image-url: ($imagePath + "interior-tour/interior-tour_quadrilocale_planimetria.png"), $background-position: center, $background-repeat: no-repeat, $background-size: contain);
      $background-image-url: ($imagePath + 'placeholder/1400x1400.jpg'),
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: contain
    );
    position: relative;
  }
  &.electron {
    @include divDimensions(1400px, 700px);
    @include margin($top: 0%, $right: 0px, $bottom: 0%, $left: 0px);
    @include border(
      $border-radius: null,
      $border-style: solid,
      $border-width: 0px,
      $border-color: $lighterGrey,
      $border-left-width: null,
      $border-top-width: 1px,
      $border-right-width: null,
      $border-bottom-width: 1px
    );

    transform: rotate(0deg);
  }

  .VT-button {
    @include divDimensions(50px, 50px);
    background-repeat: no-repeat;
    background-position: center;
    //background-image: url(#{$imagePath}/interior-tour/cono-ottico.svg);
    //background-image: url(#{$imagePath}/placeholder/48x48.svg);
    display: block;
    -webkit-backface-visibility: hidden;
    cursor: pointer;
    position: absolute;
    &.bilocale-1 {
      top: 40%;
      left: 45%;
      transform: rotate(0deg);
    }

    &.bilocale-2 {
      top: 55%;
      left: 50%;
      transform: rotate(0deg);
    }

    &.bilocale-3 {
      top: 30%;
      left: 45%;
      transform: rotate(180deg);
    }
    &.quadrilocale-1 {
      top: 70%;
      left: 60%;
      transform: rotate(90deg);
    }
    &.quadrilocale-2 {
      top: 55%;
      left: 57%;
      transform: rotate(-90deg);
    }
    &.quadrilocale-3 {
      top: 55%;
      left: 45%;
      transform: rotate(160deg);
    }
    &.quadrilocale-4 {
      top: 40%;
      left: 35%;
      transform: rotate(40deg);
    }
    &.quadrilocale-5 {
      top: 35%;
      left: 50%;
      transform: rotate(-120deg);
    }
    &.active {
      //background-image: url(#{$imagePath}/interior-tour/cono-ottico--active.svg);
      background-image: url(#{$imagePath}/placeholder/48x48.svg);
      // background-color: $secondary;
      // border-color: $secondary;
      // transition: background-color 0.3s, border-color 0.3s;
    }
  }
}


.logo-interior-tour-container {
  width: 100%;
  height: 80px;
  margin-top: 8px;

   @include border(
      $border-radius: null,
      $border-style: solid,
      $border-width: 0px,
      $border-color: $lighterGrey,
      $border-left-width: 0px,
      $border-top-width: 3px,
      $border-right-width: 0px,
      $border-bottom-width: 0px
    );
  .logo-interior-tour {
    position: relative;
    background-color: $third;
    border-radius: 100%;
    aspect-ratio: 1;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: auto;
    margin-top: 8px;
    align-items: center;
    img {
      height: 80%;
      width: 80%
    }
  }

  h4.title-interior-experience {
    color: $white;
    font-weight: 400;
    font-size: 0.9rem;
    letter-spacing: -0.02em;
    text-align: center;
    margin: 4px 0 0;
    
  }
}



@media screen and (max-width: 1400px) {
  .VT-wrapper {
    .VT-wrapper-half {
      &.left-half {
        flex: 0 0 65%;
        img {
          height: 100%;
          width: 96%;
          top: 20%;
          max-height: 600px;
        }
      }
      &.right-half {
        flex: 0 0 35%;
      }
    }
  }
}
.VT-wrapper-center {
  @include divDimensions(1300px, 80%);
  @include flexContainer($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: center);
}

.electron_VT {
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include divDimensions(100%, 100%);
  width: 1300px;
  flex: 0 0 100%;
}

.flex--interior-experience {
  @include flexContainer($direction: row, $align-items: center, $justify-content: center);
  @include divDimensions(100%, 100%);
}

.interior--experience--flexchild {
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include divDimensions(50%, 100%);
  @include backgroundImage($background-color: black, $background-image-url: null, $height: null, $background-position: center, $background-repeat: no-repeat, $background-size: cover);
  text-decoration: none;

  &.left {
    @include border(
      $border-radius: null,
      $border-style: solid,
      $border-width: 0px,
      $border-color: white,
      $border-left-width: null,
      $border-top-width: null,
      $border-right-width: 2px,
      $border-bottom-width: null
    );
    //background:linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url("../../images/interior-tour/interior-experience_1.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/placeholder/2560x1652.jpg');
    background-size: cover;
  }
  &.right {
    //background:linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url("../../images/interior-tour/interior-experience_2.jpg");
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/placeholder/2560x1652.jpg');
    background-size: cover;
  }
}

// INTERIOR EXPERIENCE

.wrapper--100.flex--interior-experience-container {
  flex-direction: row;
  margin-right: 0;
  .interior--experience--flexchild {
    width: 100%;
  }
}

.interior-experience--button {
  background-color: $secondary;
  padding: 30px;
  color: white;
  font-size: 13px;
  font-weight: 700;
  margin-top: 20px;
  border-radius: 10px;
}

.interior-experience--title {
  // background-color: $primary;
  // padding: 30px;
  color: $white;
  font-size: 15px;
  font-weight: 700;
  // margin-top: 50px;
}

.interior-experience--name {
  // background-color: $primary;
  color: $white;
  font-size: 60px;
  font-weight: 700;
  margin-top: 0px;
}

.interior-experience--button--coming-soon {
  width: 20%;
  opacity: 0.5;
}


.logo--big {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  //height: 200px;
  max-height: auto;
  max-width: auto;
  min-height: auto;
  min-width: auto;
}

.VT-wrapper-top-logo {
  max-width: 200px;
  max-height: 100px;
}

@import '../structure/media-queries';
@import '../structure/colors';
@import '../structure/layout';
@import '../structure/image-path';
$zero: 0px;
.tooSmall {
  z-index: 100;
  @include backgroundColor($white);
  @include position($position: fixed, $right: 0%);
  @include divDimensions(100vw, 100vh, $max-height: 100vh);
  @include font-face($font-family: null, $font-weight: 700, $font-style: null, $font-size: 25px);
  text-align: center;
  display: none;
  @include excludeDesktop {
    @include flexContainer($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: wrap);
  }
}

.navbar {
  @include backgroundColor($primary);
  @include flexContainer($direction: column, $align-items: center, $justify-content: space-between);
  @include divDimensions(66px, 100vh, $max-height: 100vh);
  @include position($position: fixed, $right: 0%);
  @include border($border-style: solid, $border-color: $white, $border-left-width: 15px, $border-top-width: $zero, $border-right-width: $zero, $border-bottom-width: $zero);
  z-index: 100;
  @include excludeDesktop {
    @include flexContainer($direction: row, $align-items: center, $justify-content: space-between);
    @include divDimensions(100%, 70px, $max-height: null);
    @include position($position: fixed, $right: null, $top: 0px);
    @include border($border-style: solid, $border-color: $white, $border-left-width: $zero, $border-top-width: $zero, $border-right-width: $zero, $border-bottom-width: 5px);
  }
}

.cms-top-bar {
  z-index: 101;
}

.spacing {
  @include margin($top: 15px, $bottom: 15px);
}

.navIconWrapper {
  @include flexContainer($direction: column, $align-items: center, $justify-content: space-between);
  @include margin($top: 10px, $bottom: 20px);
}

.navIcons {
  @include border($border-style: solid, $border-radius: 50%, $border-color: $white, $border-width: 2px);
  @include divDimensions(45px, 45px);
  margin: 0.5rem auto;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  &.active {
    @include border($border-style: solid, $border-color: $white, $border-width: 3px);
  }
  &.back {
    @include backgroundImage($background-image-url: null, $background-position: center, $background-repeat: no-repeat, $background-size: 50%);
  }
  &.no-tap {
    @include backgroundImage($background-image-url: null, $background-position: center, $background-repeat: no-repeat, $background-size: 120%);
    z-index: 102;
    touch-action: manipulation;
  }
  &.refresh {
    @include backgroundImage($background-image-url: null, $background-position: center, $background-repeat: no-repeat, $background-size: auto);
  }
  &.edit-mode {
    margin-top: 15px;
    @include backgroundImage($background-image-url: null, $background-position: center, $background-repeat: no-repeat, $background-size: 60%);
  }
  // &.close-second-window {
  //     @include backgroundImage ($background-image-url: ("../../images/icons/close-second-window.svg"), $background-position: center, $background-repeat: no-repeat, $background-size: 60%);
  //     z-index: 102;
  //     touch-action: manipulation;
  //     margin-bottom: 10px;
  // }
  &.sales-tool {
    //@include backgroundImage ($background-image-url: ("../../images/icons/pie-chart.svg"), $background-position: center, $background-repeat: no-repeat, $background-size: 60%);
    @include backgroundImage(
      $background-image-url: (
        '/images/placeholder/48x48.svg',
      ),
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: 60%
    );
    z-index: 102;
    touch-action: manipulation;
  }
  &.hidden {
    opacity: 0;
    margin-bottom: 10px;
    // display: none;
  }
}

.black-overlay {
  @include divDimensions(10000px, 100vh);
  top: -2300px;
  opacity: 0;
  transition: opacity 0.3s ease 0s, top 0s linear 0.5s;
  background-color: black;
  position: fixed;
  &.off {
    top: 0px;
    opacity: 1;
    transition: top 0s linear 0s, opacity 0.3s ease 0.01s;
  }
  &.opaque {
    opacity: 0.5;
    display: block;
  }
}

#navbar {
  border: none;
  border-left: 3px solid white;
}

@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";


.fullscreen-overlay {
    //@include backgroundColor($primary);
    @include flexContainer ($direction: column, $align-items: center, $justify-content: center);
    @include divDimensions (100vw, 100vh, $max-height: 100vh);
    @include position($position: fixed);
    @include padding($top: null, $right: 70px, $bottom: null, $left: null);
    @include backgroundColor($black, $opacity: .9);
    z-index: 100000;

    &.hide {
        display: none;
    }
}

.fullscreen-overlay-sw-progress {
    @include flexContainer ($direction: column, $align-items: center, $justify-content: center);
    @include position($position: fixed);
    @include backgroundColor(rgba(0,0,0,0.9));
    width: 100vw;
    height: 100vh;
    z-index: 100000;

    &.hide {
        display: none;
    }
}

.fullscreen-message {
    @include font-face($font-family: $primaryFont, $font-weight: 100, $font-size: 20px);
    @include text($text-color:$white, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: none, $text-shadow: null, $text-transform: uppercase);
    margin-bottom: 50px;
}


.fullscreen-button {
    @include font-face($font-family: $primaryFont, $font-weight: 100, $font-size: 15px);
    @include text($text-color:$white, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: none, $text-shadow: null, $text-transform: uppercase);
    padding: 5px 25px;
    background-color: transparent;
    cursor: pointer;
    
    
    //border-style: outset;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(118, 118, 118);

    -webkit-transition-property: all;
    -webkit-transition-duration: 0.9s;
    -webkit-transition-timing-function: ease;

    &:hover {
        @include text($text-color:$black, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: none, $text-shadow: null, $text-transform: uppercase);
        @include backgroundColor($white, $opacity: 1);


    }

}

@media (max-width: 991px) {
	#desk-tablet-content {
        display: none;
    }
    #navbar {
        display: none;
    }
}
@media (min-width: 992px) {
	#mobile-content {
        display: none;
    }
}

.mobile-message {
	display: flex;
	font-family: Montserrat, sans-serif;
    font-size: 30px;
    line-height: 1.5em;
	text-align: center;
    position: fixed;
    top: 40%;
    left: 20%;
}

@media print {
    #mobile-content {
        display: none !important;
    }
    #desk-tablet-content {
        display: block !important;
    }
    .floorplanning-container {
        z-index: auto !important;
        overflow-y: initial !important;
    }
    .riepilogo__wrapper-print {
        margin-left: 55px !important;
    }
    header, .offcanvas-cart, .sidebar, .riepilogo__wrapper, .nav--pagination, .nav--configurator, .footer-notes, .navbar {
        display: none !important;
    }
    body {
        overflow: initial !important;
        position: initial !important;
    }
    .heroSection {
        position: initial !important;
    }
}
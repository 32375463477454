@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";
@import "../structure/texts";

$margin: 10px;

.lightbox-wapper {
  @include divDimensions(100%, 100%, $min-height: 70vh);
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $justify-content: center
  );
  @include position($position: relative);
  @include padding($top: 50px, $right: 0px, $bottom: 50px, $left: 10px);
  // @include backgroundImage ($background-color: transparent, $background-image-url: ("/images/placeholder/1500x1500.jpg"), $background-gradient: (180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), $background-position: 50%, $background-repeat: no-repeat, $background-size: cover);
  // z-index: -100;

  &.guida {
    //background-image: url("../../images/guida/guida_3.jpg");
    background-image: url("/images/placeholder/3500x2475.jpg");
    background-color: #e5e6e1;
    background-size: contain;
  }

  &.capitolato {
    // z-index: -100;

    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    );
  }

  &.interior-pack {
    @extend.capitolato;
  }

  &.virtual-photo {
    // background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    //     url("../../images/Garofalo_Esterna02_rev004_contrastata.jpg");
    @include flexContainer(
      $direction: column,
      $align-items: flex-start,
      $justify-content: center
    );
    padding: 0px;
    margin: 0px;
    // z-index: -1;
  }

  &.lifestyle {
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.8)
    );
    @include flexContainer(
      $direction: row,
      $align-items: flex-start,
      $justify-content: top
    );
    padding: 30px;
  }
}

//virtual photo additional divs and classes
.thumbnail-wrap-area {
  @include position($position: relative);
  @include flexContainer(
    $direction: column,
    $align-items: top,
    $justify-content: center
  );
  padding: 15px;
  @include divDimensions(100%, 100%, $max-width: 450px);
  @include margin($top: null, $right: 50px, $bottom: null, $left: 50px);
  overflow: hidden;
  background-color: $fifth;
  -webkit-box-shadow: 7px 7px 7px -2px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 7px 7px 7px -2px rgba(0, 0, 0, 0.16);
  box-shadow: 7px 7px 7px -2px rgba(0, 0, 0, 0.16);

  &.electron,
  &.virtual {
    @include divDimensions(60%, 95%, $max-width: 450px);
  }
  &.one-row {
    @include divDimensions(30%, 95%, $max-width: 250px);
  }
}

.logo--virtualphoto {
  @include backgroundImage(
    $background-color: null,
    //$background-image-url: "../../images/icons/logo.svg",
    $background-image-url: null,
    $height: null,
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: contain
  );
  @include divDimensions(40px, 40px);
  z-index: 3;
}

.title--virtualphoto {
  @include text(
    $text-color: $white,
    $letter-spacing: null,
    $line-height: null,
    $text-align: center,
    $text-decoration: null,
    $text-shadow: null,
    $text-transform: null
  );
  @include font-face(
    $font-family: $thirdFont,
    $font-weight: 500,
    $font-size: 14px
  );
  text-transform: uppercase;
  z-index: 3;
  margin: 10px;
}

.lightbox-header--wrapper {
  padding: 10px;
  @include divDimensions(100%, auto);
  @include flexContainer(
    $direction: column,
    $align-items: center,
    $justify-content: center
  );
  position: relative;
  overflow: hidden;
  grid-area: 1 / 1 / 3 / 2;
  &.esterni {
    grid-area: 1 / 1 / 3 / 2;
  }
  &.interni {
    grid-area: 1 / 1 / 3 / 2;
  }
  &.concept {
    // grid-area: 1 / 1 / 3 / 2;
  }
}
.lightbox-header--image {
  @include divDimensions(100%, 100%);
  position: absolute;
  @include backgroundImage(
    $background-color: null,
    //$background-image-url: "../../images/gallery-header_interna.jpg",
    // $background-image-url: "/images/placeholder/400x400.jpg",
    $height: null,
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: cover
  );
  filter: blur(2px);
  -webkit-filter: blur(2px);
  overflow: hidden;

  &.interni {
    @include backgroundImage(
      $background-color: null,
      //$background-image-url: "../../images/gallery-header_interna.jpg",
      // $background-image-url: "/images/placeholder/400x400.jpg",
      $height: null,
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: cover
    );
  }
  &.esterni {
    @include backgroundImage(
      $background-color: null,
      //$background-image-url: "../../images/gallery-header_esterna.jpg",
      // $background-image-url: "/images/placeholder/400x400.jpg",
      $height: null,
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: cover
    );
  }
  &.Aree.Comuni {
    @include backgroundImage(
      $background-color: null,
      //$background-image-url: "../../images/gallery-header_concept.jpg",
      // $background-image-url: "/images/placeholder/400x400.jpg",
      $height: null,
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: cover
    );
  }
}

.overflow {
  // @include flexContainer ($direction: row, $align-items: center, $align-content: flex-start, $justify-content: null, $flex-wrap: wrap);
  @include divDimensions(
    100%,
    100%,
    $max-width: auto,
    $max-height: auto,
    $min-width: auto,
    $min-height: auto
  );
  overflow: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &.esterni {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }

  &.interni {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(10, 1fr);
  }
  &.concept {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }

  column-gap: 2px;
  row-gap: 2px;
}

//end of virtual photo additional divs and classes

.lightbox-thumbnail {
  @include position($position: relative);
  @include divDimensions(150px, 150px);
  @include backgroundImage(
    $background-color: null,
    $background-image-url: null,
    $height: null,
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: cover
  );
  cursor: pointer;

  &.electron {
    @include divDimensions(150px, 150px);
  }

  &.capitolato,
  &.interior-pack {
    @include margin(
      $top: $margin,
      $right: $margin,
      $bottom: $margin,
      $left: $margin
    );
    @include divDimensions(100px, 35px);
    @include backgroundImage(
      $background-color: null,
      $background-image-url: null,
      $height: null,
      $background-position: center,
      $background-repeat: no-repeat,
      $background-size: contain
    );
    margin: 30px;
  }

  &.virtual-photo {
    @include divDimensions(100%, 100%);
    &.esterni {
      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;
      }
      &:nth-child(2) {
        grid-area: 1 / 2 / 4 / 3; // background-size: 400%;
        // background-position: 90% 10%;
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 6 / 2; // background-size: 150%;
        // background-position: 90% 50%;
      }
      &:nth-child(4) {
        grid-area: 4 / 2 / 5 / 3;
      }
      &:nth-child(5) {
        grid-area: 6 / 1 / 8 / 2; // background-size: cover;
        // background-position: 50% 50%;
      }
      &:nth-child(6) {
        grid-area: 5 / 2 / 7 / 3; // background-size: 150%;
        // background-position: 20% 50%;
      }
      &:nth-child(7) {
        grid-area: 8 / 1 / 10 / 2;
      }
      &:nth-child(8) {
        grid-area: 7 / 2 / 11 / 3;
      }
    }

    &.concept {
      &:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
      }
      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
        background-size: cover;
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 5 / 2;
        background-size: 400%;
      }
    }

    &.interni {
        &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;
      }
      &:nth-child(2) {
        grid-area: 1 / 2 / 4 / 3; // background-size: 400%;
        // background-position: 90% 10%;
      }
      &:nth-child(3) {
        grid-area: 3 / 1 / 6 / 2; // background-size: 150%;
        // background-position: 90% 50%;
      }
      &:nth-child(4) {
        grid-area: 4 / 2 / 5 / 3;
      }
      &:nth-child(5) {
        grid-area: 6 / 1 / 8 / 2; // background-size: cover;
        // background-position: 50% 50%;
      }
      &:nth-child(6) {
        grid-area: 5 / 2 / 7 / 3; // background-size: 150%;
        // background-position: 20% 50%;
      }
      &:nth-child(7) {
        grid-area: 8 / 1 / 10 / 2;
      }
      &:nth-child(8) {
        grid-area: 7 / 2 / 11 / 3;
      }
    }
  }
}

/***overriding plugin styles***/
.fslightbox-thumb-wrapper {
  margin: 5px !important;
  border-bottom: 2px solid $transparent !important;
  max-height: 100px !important;
  padding-top: 10px;
}

.fslightbox-thumbs,
.fslightbox-thumb-active {
  @include flexContainer(
    $direction: row,
    $align-items: center,
    $justify-content: center
  );
  max-height: 100%;
}

.fslightbox-thumb {
  // max-width: 120px !important;
  // max-height: 120px !important;
  background-size: contain !important;
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.fslightbox-thumb.fslightbox-thumb-active {
  border: 0px !important;
  margin-bottom: 5px;
  border-bottom: 5px solid $secondary !important;
}

.fslightbox-toolbar-button:nth-child(5) {
  display: none;
}

.fslightbox-slide-btn.fslightbox-flex-centered {
  @include backgroundImage(
    $background-color: $secondary,
    $background-image-url: null,
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: 78%
  );
  border-radius: 50%;
  cursor: pointer;
  // display: none;
}

.fslightbox-slide-btn-container.fslightbox-slide-btn-container-previous {
  // left: 30px;
}

.fslightbox-slide-btn-container.fslightbox-slide-btn-container-next {
  // right: 80px;
}

.fslightbox-nav.fslightbox-absoluted {
  left: -100px;
  //display: none;
}

.fslightbox-container {
  z-index: 100000 !important;
}

.fslightbox-full-dimension {
  z-index: 200000;
}

.fslightbox-cursor-grabbing {
  z-index: 200000;
}

// .fslightbox-container {
//   z-index: 5 !important;
// }
.fslightbox-flex-centered {
  transform: translate(0px, 0px) scale(0.903448);
}

.fslightbox-source-outer,
.fslightbox-source-inner,
.fslightbox-source {
  height: 100% !important;
  width: 100% !important;
  object-fit: contain;
}

.fslightbox-opacity-1 {
  position: fixed;
  z-index: 999999999;
}

.linea {
  width: 80%;
  height: 1px;
  background-color: white;
  z-index: 2;
  margin-top: 10px;
  margin-bottom: 5px;
}

.fade-lightbox {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// fix thumbnails inner size
.fslightbox-thumbs-inner {
  width: 100%;
  justify-content: center !important;
}

@media only screen and (min-device-width : 1500px) and (orientation : landscape) {
	.fslightbox-thumbs {
		background: linear-gradient(0deg,rgba(0,0,0,0),#1e1e1e 66%) !important;
		left: -5% !important;
		top: 0 !important;
		width: 106% !important;
	}
	.fslightbox-toolbar {
		width: 15%;
		bottom: 0 !important;
		left: 110px !important;
		top: -54% !important;
       z-index: 4 !important;
	}
	.fslightbox-nav.fslightbox-absoluted {
		top: 10% !important;
	}
	.fslightbox-toolbar-button.fslightbox-flex-centered {
		width: 20%;
	}
	.fslightbox-source-inner {
		img {
			transform: translateY(190px) !important;
			height: calc(100% - 30px) !important;
		}
		transform-origin: center 120% !important;
		bottom: 0 !important;
	}
}
@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/image-path";

$virtual-photo-icon: "/immagini/homepage/icons/icon_VIRTUAL-PHOTO.svg";

.flex--virtualPhoto {

    @include divDimensions (100%, 100%, $min-height: 70vh);
    @include flexContainer ($direction: row, $align-items: center, $justify-content: center);
    @include position($position: relative);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));

}

.logo--big{
    @include divDimensions (500px, 500px, $min-height: null);
    @include position($position: fixed);
    //background-image: url("../../images/icons/logo-black.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: -80px;
    opacity: .03;
    // z-index: -1;
}

.logo--virtual-photo-wrapper{
    @include divDimensions (200px, 50px, $min-height: auto, $min-width: auto);
    margin: 10px 0;
    padding: 10px 0;
    @include flexContainer ($direction: row, $align-items: center, $justify-content: center);
    h5{
        text-transform: uppercase;
        text-align: center;
    }
}
.icon-virtual-photo{
    @include divDimensions (40px, 40px, $min-height: null);
    //background-image: url("../../images/icons/icon_virtual-photo_BLU.svg");
    //background-image: url($virtual-photo-icon);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 15px;
    filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)
}

.virtual-photo-wrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: center;
}
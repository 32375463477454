@import "./media-queries";
@import "./colors";
@import "./layout";
@import "./fonts";
@import "./image-path";

.button {
    @include backgroundColor($primary);
    @include divDimensions (400px, 10px);
}

.nextStep {
    margin-top: 10px;
    @include backgroundColor($secondary);
    @include text($text-color: $white, $text-align: center);
    @include font-face($font-family: $primaryFont, $font-weight: 500, $font-size: 18px);
    text-transform: uppercase;
    @include flexContainer ($direction: row, $align-items: center, $align-content: null, $justify-content: center, $flex-wrap: null);
    @include divDimensions (200px, 40px);
    outline: 2px solid $secondary;
    outline-offset: 5px;
}

@import "../structure/media-queries";
@import "../structure/colors";
@import "../structure/layout";
@import "../structure/texts";
@import "../structure/buttons";
@import "../structure/image-path";


.a-enter-vr-button{
    display: none;
}


.frame {
    @include divDimensions (100%, 100vh, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: 600px); //@include backgroundColor($primary, $opacity: 0.5);
    @include flexContainer ($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    @include position($position: fixed);
    z-index: 1;
    overflow-x: hidden;
    box-sizing: border-box;
    @include border($border-radius: null, $border-style: null, $border-width: 0px, $border-color: null, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
}

.menu-wrapper {
    @include divDimensions (100%, 350px, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null); 
    //@include backgroundColor($primary, $opacity: 0.5);
    overflow-x: hidden;
    @include flexContainer ($direction: column, $align-items: center, $align-content: flex-end, $justify-content: flex-end, $flex-wrap: null);
    @include position($position: absolute);
    padding: 10px;
    padding-right: 90px;
    bottom: 0px;
    left:0px;
    z-index: 10;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
    transition: all .5s;


    &.hide {
        transform: translateY(130px);
    }

}

.button-wrapper {
    @include divDimensions (100%, auto, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null);
    @include flexContainer ($direction: row-reverse, $align-items: flex-end, $align-content: null, $justify-content: null, $flex-wrap: null);
}

.button-background {
    @include divDimensions (100%, 150px, $max-width: 100%, $max-height: auto, $min-width: null, $min-height: null);
    @include flexContainer ($direction: column, $align-items: center, $align-content: center, $justify-content: center, $flex-wrap: null);
    @include backgroundColor(grey, $opacity: 1);
    @include margin($top: 0px, $right: 10px, $bottom: 0px, $left: 5px);
    color: white;
    @include border($border-radius: null, $border-style: solid, $border-width: 0px, $border-color:grey, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
    cursor: pointer;
    transition: all 1s;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    .text_view_nome {
        z-index: 4;
    }

    &.active {
        @include border($border-radius: null, $border-style: solid, $border-width: 5px, $border-color: white, $border-left-width: null, $border-top-width: null, $border-right-width: null, $border-bottom-width: null);
    }
}

.overlay {
    position: absolute;
    /* Sit on top of the page content */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
    /* Add a pointer on hover */
    background-position: center;
    /* icon center in thumbnail in vr */
}

.arrow {
    @include divDimensions (30px, 30px, $max-width: null, $max-height: null, $min-width: null, $min-height: null);
    @include margin($top: 5px, $right: 5px, $bottom: 5px, $left: 5px);
    cursor: pointer;
    transform: rotate(225deg);
    transition: all .5s;

    @include border($border-radius: null, $border-style: solid, $border-width: null, $border-color: white, $border-left-width: 3px, $border-top-width: 3px, $border-right-width: 0px, $border-bottom-width: 0px);

    &.hide {
        transform: rotate(45deg);
    }
}

#viewer {
    width: 100vw;
    height: 50vh;
}

@media only screen and (min-device-width : 1500px) and (orientation : landscape) {
	.menu-wrapper {
		left: -0,8% !important;
		width: 96% !important;
		padding: 0 !important;
		top: 0 !important;
		float: left !important;
		transform: rotate(180deg) !important;
		margin-top: 0px !important;
	}
	.menu-wrapper.hide {
		margin-top: -101px !important;
		top: 0 !important;
		float: left !important;
		transform: rotate(180deg) !important;
	}
    .text-change-view {
		transform: rotate(180deg);
	}
    .overlay {
		transform: rotate(180deg);
	}
	.text_view_nome {
		transform: rotate(180deg);
	}
}
@import "../structure/colors";
@import "../structure/fonts";


.genericErrorTitle {
    font-size: 36px !important;
    font-weight: 700 !important;
    margin-bottom: 24px !important;
}

.genericErrorButton {
    border-radius: 0 !important;
    color: white !important;

    font-weight: 700 !important;
    font-size: 16px !important;
    height: 48px !important;
    width: 320px !important;
    max-width: 90% !important;
    cursor: pointer !important;
    -webkit-transition: filter ease 100ms !important;
    transition: filter ease 100ms !important;
}

.genericErrorMessage {
    color: #4D4D4D  !important;
}
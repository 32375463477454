@import '../structure/media-queries';
@import '../structure/colors';
@import '../structure/layout';
@import '../structure/image-path';
@import '../structure/fonts';
// @import "~react-image-gallery/styles/scss/image-gallery.scss";
.image-gallery {
  @include divDimensions(100vw, 100vh, $max-height: 100vh);
}
body {
  @include margin($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
  @include padding($top: $zero, $right: $zero, $bottom: $zero, $left: $zero);
  overflow: hidden;

  position: fixed;
  top: 0%;
  bottom: 0%;
  right: 10%;
  left: 0%;
}

.wrapper--100 {
  //@include backgroundColor($primary);
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include divDimensions(100%, 100vh, $max-height: 100vh);
  // @include position($position: fixed);
  @include padding($top: null, $right: null, $bottom: null, $left: null);
  margin-right: 66px;
  &.second-window {
    @include padding($top: null, $right: 0px, $bottom: null, $left: null);
  }
  // z-index: 0;
}

.header-wrapper {
  @include flexContainer($direction: row, $align-items: center, $justify-content: center);
  @include position($position: absolute, $top: 0px, $right: null, $bottom: null, $left: 0px);
  z-index: 10;
}

.header-wrapper__square {
  @include divDimensions(60px, 40px);
  @include backgroundColor($secondary);
  margin-right: 11px;
}

.header_paragraph {
  // color: $primary;
  @include font-face($font-family: null, $font-weight: null, $font-style: null, $font-size: null, $font-color: null);
  text-transform: uppercase;
}

.link {
  @include divDimensions(100%, 100px);
  @include position($position: absolute, $top: null, $right: null, $bottom: 0px, $left: null);
  @include flexContainer($direction: row, $align-items: center, $justify-content: center);
  z-index: 1;
  cursor: pointer;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

.go-icon {
  @include backgroundImage(
    //$background-image-url: "../../images/icons/logo.svg",
    $background-image-url: '/images/placeholder/48x48.svg',
    $background-position: center,
    $background-repeat: no-repeat,
    $background-size: cover
  );
  @include divDimensions(35px, 35px);
  @include margin($top: null, $right: 10px, $bottom: null, $left: null);
}

.flex--child.capitolato {
  display: flex;
  align-items: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    min-height: 300px;
    flex: 50%;
    height: 40%;

    .card {
      &.switcher {
        display: inline-flex;
        margin: 10px 20px;
        min-width: 200px;
        min-height: 300px;
        width: 80%;
        height: 80%;
      }
    }
  }
}

//card styles
.card {
  @include backgroundImage($background-position: center, $background-repeat: no-repeat, $background-size: cover);
  @include divDimensions(100%, 100%, $min-height: auto);
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  text-decoration: none;
  @include position($position: relative, $top: null, $right: null, $bottom: null, $left: null);
  box-shadow: 2px 2px 12px 2px rgba(0, 0, 0, 0.3);
  //animations
  /* Animation */
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  @keyframes fadeInUp {
    from {
      transform: translate3d(0, 40px, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: both;

  &._13 {
    grid-column: span 2;
    background-position-y: 75%;
  }

  &.switcher {
    @include divDimensions(25%, 50%, $min-height: 180px);
    @include flexContainer($direction: row, $align-items: center, $justify-content: center);
    margin: 40px;
  }

  &.book {
    @include divDimensions(450px, 450px, $min-height: 200px);
    margin: 20px;
    &.residenze {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/book/card_book-residenze.jpg",
        $background-image-url: '/images/placeholder/1500x1500.jpg',
        $background-position: center,
        $background-repeat: no-repeat,
        $background-size: 100%
      );
    }
    &.verde {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/book/card_book-verde.jpg",
        $background-image-url: '/images/placeholder/1500x1500.jpg',
        $background-position: center,
        $background-repeat: no-repeat,
        $background-size: 100%
      );
    }
  }

  &.planimetrie {
    @extend.book;
    &.base {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/planimetrie/card-layout_base.jpg",
        $background-image-url: '/images/placeholder/2560x3420.jpg',
        $background-position: (
          50%,
          90%,
        ),
        $background-repeat: no-repeat,
        $background-size: 100%
      );
    }
    &.varianti {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/planimetrie/card-layout_varianti.jpg",
        $background-image-url: '/images/placeholder/2560x3833.jpg',
        $background-position: (
          50%,
          30%,
        ),
        $background-repeat: no-repeat,
        $background-size: 100%
      );
    }
    &.bilocali {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/planimetrie/card-layout_bilocali.jpg",
        $background-image-url: '/images/placeholder/2560x3840.jpg',
        $background-position: (
          50%,
          30%,
        ),
        $background-repeat: no-repeat,
        $background-size: cover
      );
    }
    &.trilocali {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/planimetrie/card-layout_trilocali.jpg",
        $background-image-url: '/images/placeholder/2560x3840.jpg',
        $background-position: (
          50%,
          30%,
        ),
        $background-repeat: no-repeat,
        $background-size: cover
      );
    }
    &.quadrilocali {
      @include backgroundImage(
        $background-color: null,
        //$background-image-url: "../../images/planimetrie/card-layout_quadrilocali.jpg",
        $background-image-url: '/images/placeholder/2560x1707.jpg',
        $background-position: (
          50%,
          30%,
        ),
        $background-repeat: no-repeat,
        $background-size: cover
      );
    }
  }

  &.is-visitor, &.coming-soon{
    cursor: not-allowed;
  }
}

.card--icon {
  @include backgroundImage($background-color: null, $background-image-url: null, $background-position: center, $background-repeat: no-repeat, $background-size: 100%);
  @include border(
    $border-radius: 100%,
    $border-style: solid,
    $border-width: 0px,
    $border-color: white,
    $border-left-width: null,
    $border-top-width: null,
    $border-right-width: null,
    $border-bottom-width: null
  );
  @include divDimensions(50px, 50px);
  &.coming-soon {
    @include backgroundImage($background-color: $third);
  }
  &.is-visitor {
    @include backgroundImage($background-color: $third);
  }
  &.bilocali {
    @include backgroundImage(
      $background-color: null,
      //$background-image-url: "../../images/planimetrie/icon_bilocali.svg",
      $background-image-url: '/images/placeholder/48x48.svg',
      $background-position: (
        50%,
        30%,
      ),
      $background-repeat: no-repeat,
      $background-size: cover
    );
  }
  &.trilocali {
    @include backgroundImage(
      $background-color: null,
      //$background-image-url: "../../images/planimetrie/icon_trilocali.svg",
      $background-image-url: '/images/placeholder/48x48.svg',
      $background-position: (
        50%,
        30%,
      ),
      $background-repeat: no-repeat,
      $background-size: cover
    );
  }
  &.quadrilocali {
    @include backgroundImage(
      $background-color: null,
      //$background-image-url: "../../images/planimetrie/icon_quadrilocali.svg",
      $background-image-url: '/images/placeholder/48x48.svg',
      $background-position: (
        50%,
        30%,
      ),
      $background-repeat: no-repeat,
      $background-size: cover
    );
  }
}

.card--coming-soon {
  @include divDimensions(100%, 30px);
  background-color: $third;
  @include text($text-color: $white, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: none, $text-shadow: null, $text-transform: uppercase);
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include position($position: absolute, $top: null, $right: null, $bottom: 30px, $left: null);
  margin: 0px;
}
.card--is-visitor {
  @include divDimensions(100%, 30px);
  background-color: $third;
  @include text($text-color: $white, $letter-spacing: null, $line-height: null, $text-align: null, $text-decoration: none, $text-shadow: null, $text-transform: uppercase);
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include position($position: absolute, $top: null, $right: null, $bottom: 30px, $left: null);
  margin: 0px;
}

.card--title {
  @include divDimensions(100%, 30px);
  background-color: $white;
  @include text($text-color: $secondary, $letter-spacing: null, $line-height: null, $text-align: center, $text-decoration: none, $text-shadow: null, $text-transform: uppercase);
  @include flexContainer($direction: column, $align-items: center, $justify-content: center);
  @include position($position: absolute, $top: null, $right: null, $bottom: 0px, $left: null);
  margin: 0px;
  // @include font-face($font-family: $thirdFont, $font-weight: null, $font-style: null, $font-size: null, $font-color: null);
}

.colorFont {
  @include font-face($font-family: $primaryFont, $font-weight: null, $font-style: null, $font-size: null, $font-color: $third);
}

.boldFont {
  @include font-face($font-family: $secondaryFont, $font-weight: null, $font-style: null, $font-size: null, $font-color: null);
  text-transform: uppercase;
}

// PACCHETTI ARREDO

h2.tipology-arredo {
  @include font-face($font-family: $primaryFont, $font-weight: 400, $font-style: null, $font-size: 3rem, $font-color: #fff);
  @include position($position: absolute, $top: 2rem, $right: null, $bottom: null, $left: 4rem);
  z-index: 3;
}
.flex--child.arredi {
  flex-wrap: wrap;
  @include flexContainer($direction: row, $align-items: center, $justify-content: center);
  @include padding($top: 10rem, $right: 4rem, $bottom: 10rem, $left: 4rem);
}

.card.layout-button {
  background-image: none;
  @include divDimensions(inherit, 50px);
  flex: 0 0 20%;
  min-height: 50px;
  @include margin($top: 0, $right: 40px, $bottom: 0, $left: 0);
  p {
    @include divDimensions(100%, 100%);
    background-color: $secondary;
    color: $primary;
  }
}

.white-loader {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  background-color: white;
}
